import styled from 'styled-components'

export const StyledSelectedTherapist = styled.div`
  display: block;
  padding: 1rem 0;
  text-align: center;
  position: relative;

  img.photo {
    width: 138px;
    aspect-ratio: 3 / 4;
    margin: 2rem auto 0;
    border-radius: 10px;
    grid-row: 1 / 3;
  }

  h3 {
    font-size: var(--fontSize__h5);
    margin: 1rem 0;
  }

  form {
    margin: 0;
  }
`
