import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// TODO: use new gatsby image for fallback image

const TherapistImage = ({ name, photo }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      {photo ? (
        <img className="photo" src={photo} alt={name} />
      ) : (
        <img
          className="photo"
          src={data.placeholderImage.childImageSharp.fluid.src}
          alt={name}
        />
      )}
    </>
  )
}

export default TherapistImage
