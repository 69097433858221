import styled from 'styled-components'

export const StyledTherapists = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  gap: 20px;
  margin-bottom: 5rem;

  @media (max-width: 420px) {
    grid-template-columns: 1fr 1fr;
  }
`
