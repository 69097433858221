import React from 'react'
import { Drawer } from '../Drawer'
import TherapistImage from '../Therapist/TherapistImage.component'
import { Submit } from '../Submit'
import { StyledSelectedTherapist } from './SelectedTherapist.styles'

const SelectedTherapist = ({
  therapistSelected,
  setTherapistSelected,
  therapistNode,
  glpgLocation,
}) => {
  return (
    <Drawer
      anchor="bottom"
      state={therapistSelected}
      setState={setTherapistSelected}
    >
      {therapistNode && (
        <StyledSelectedTherapist>
          <TherapistImage
            name={therapistNode.title}
            photo={therapistNode.therapistsAcf.therapistPhoto}
          />
          <h3>
            You are checking in with <br />
            {therapistNode.title}
          </h3>
          <Submit
            clinician={therapistNode.therapistsAcf.therapistSalesforceId}
          />
        </StyledSelectedTherapist>
      )}
    </Drawer>
  )
}

export default SelectedTherapist
