import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GlobalContext } from '../../utils/GlobalContext'
import { Therapist } from '../Therapist'
import { SelectedTherapist } from '../SelectedTherapist'
import { StyledTherapists } from './Therapists.styles'

const Therapists = ({ search }) => {
  const [therapistNode, setTherapistNode] = useState()
  const glpgLocation = search.replace('?location=', '')
  const {
    therapistSelected,
    setTherapistSelected,
    unknownActive,
    setUnknownActive,
  } = useContext(GlobalContext)

  const data = useStaticQuery(graphql`
    {
      allWpTherapist(sort: { fields: title }) {
        edges {
          node {
            title
            therapistsAcf {
              therapistPhoto
              therapistSalesforceId
              therapistNpi
            }
            therapistLocations {
              nodes {
                name
                description
              }
            }
          }
        }
      }
    }
  `)
  const therapists = data.allWpTherapist.edges

  return (
    <>
      <StyledTherapists>
        {therapists.map(
          ({ node }) =>
            node.therapistLocations.nodes &&
            node.therapistLocations.nodes.some(({ description }) => {
              return description.toString() === glpgLocation
            }) && (
              <div
                style={{ cursor: `pointer` }}
                key={node.therapistsAcf.therapistSalesforceId}
                onClick={() => {
                  setTherapistNode(node), setTherapistSelected(true)
                }}
              >
                <Therapist node={node} />
              </div>
            )
        )}
        <div
          style={{ cursor: `pointer` }}
          onClick={() => {
            setUnknownActive(true)
          }}
        >
          <Therapist type="unknown" node="unknown" />
        </div>
      </StyledTherapists>
      <SelectedTherapist
        therapistNode={therapistNode}
        therapistSelected={therapistSelected}
        setTherapistSelected={setTherapistSelected}
        glpgLocation={glpgLocation}
        unknownActive={unknownActive}
      />
    </>
  )
}

export default Therapists
