import React from 'react'
import TherapistImage from './TherapistImage.component'
import { StyledTherapist } from './Therapist.styles'

const Therapist = ({ node }) => {
  return (
    <StyledTherapist>
      {node !== `unknown` ? (
        <>
          <TherapistImage
            name={node.title}
            photo={node.therapistsAcf.therapistPhoto}
          />
          <h2>{node.title}</h2>
        </>
      ) : (
        <>
          <TherapistImage />
          <h2>I don't know or see my therapist</h2>
        </>
      )}
    </StyledTherapist>
  )
}

export default Therapist
