import styled from 'styled-components'

export const StyledTherapist = styled.div`
  position: relative;
  aspect-ratio: 3 / 4;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(16, 42, 67, 0) 0%, #102a43 100%);
  }

  h2 {
    margin: 0;
    position: absolute;
    bottom: 1rem;
    right: 10px;
    left: 10px;
    font-size: var(--fontSize__base);
    color: #fff;
    letter-spacing: 0;
    line-height: 1.3;
    z-index: 1;
  }
`
