import React from 'react'
import { Therapists } from '../components/Therapists'

const CheckinPage = ({ location }) => {
  const { search } = location

  return (
      <div>
        <h3>Please select your therapist</h3>
        <Therapists search={search} />
      </div>
  )
}

export default CheckinPage
